import React, { Fragment, ReactElement, ReactFragment } from 'react';
import DashboardNotification from './DashboardNotification';
import DanlawVehicle from '../../models/DanlawVehicle';
import { setCustomerDashboardPanel } from '../../helpers/navigationHelpers';

interface DashboardNotificationProps {
    icon: string;
    header: string;
    message: string;
    onClick?: any;
    display?: string;
    priority: number;
    id: string;
}

export function BeingTowed(): ReactElement {
    return (
        <DashboardNotification 
            icon="notification-center-alert-icon" 
            header="Your Vehicle is Being Towed" 
            message="See its current location." 
            onClick={() => setCustomerDashboardPanel("vehicles")} 
            priority={5}
            id="BeingTowed"/>
    )
}

export function WasTowed(): ReactElement {
    return (
        <DashboardNotification 
            icon="notification-center-alert-icon" 
            header="Your Vehicle Was Towed" 
            message="See its current location." 
            onClick={() => setCustomerDashboardPanel("vehicles")} 
            priority={6}
            id="WasTowed"/>
    )
}

export function Reconnect(vehicle: DanlawVehicle): ReactElement {
    return (
        <DashboardNotification 
            icon="notification-center-alert-icon" 
            header="Reconnect Your Device" 
            message={vehicle?.deviceInfo?.deviceId + " in the " + vehicle?.vehicleType + " is disconnected."} 
            onClick={() => setCustomerDashboardPanel("devices")} 
            priority={2}
            id="Reconnect"/>
    )
}

export function AssignVehicle(): ReactElement {
    return (
        <DashboardNotification 
            icon="notification-center-vehicle-icon" 
            header="Assign A Vehicle To Your Device" 
            message="Once your device is assigned, you'll be able to track your vehicle's location on the Vehicles page." 
            onClick={() => setCustomerDashboardPanel("devices")} 
            priority={4}
            id="AssignVehicle"/>
    )
}

export function TrackShipment(): ReactElement {
    return (
        <DashboardNotification 
            icon="notification-center-shipment-icon" 
            header="Track Your Shipment" 
            message="Your devices are on the way." 
            onClick={() => setCustomerDashboardPanel("devices")}
            priority={3}
            id="TrackShipment"/>
    )
}

export function InstallDevice(): ReactElement {
    return (
        <DashboardNotification 
            icon="notification-center-install-icon" 
            header="Install Your Device" 
            message="Your device(s) have arrived and are ready for install." 
            onClick={() => setCustomerDashboardPanel("devices")} 
            display='x'
            priority={1}
            id="InstallDevice"/>
    )
}

export function CaughtUp(): ReactElement {
    return (
        <DashboardNotification 
            icon="notification-center-success-icon" 
            header="All caught up!"
            message="Everything looks good. Happy tracking." 
            onClick={null} 
            display='none' 
            priority={7}
            id="CaughtUp"/>
    )
}

export function DevicesConnected(vehicle: DanlawVehicle): ReactElement {
    let date = new Date();

    if (vehicle.deviceInfo && vehicle.deviceInfo.lastConnectionTime && (Date.parse(vehicle.deviceInfo.lastConnectionTime) < date.setDate(date.getDate() + 1) )) {
        return (
            <DashboardNotification 
                icon="notification-center-success-icon" 
                header="Devices Connected!" 
                message={vehicle?.deviceInfo?.deviceId + " was successfully plugged into the  " + vehicle?.vehicleType + "."} 
                onClick={() => null} 
                display={displayTypes[1]}
                priority={2}
                id="DevicesConnected"/>
        )
    } else {
        return <></>;
    }
}

export function CoverageStarts(date: string): ReactElement {
    return (
        <DashboardNotification 
            icon="notification-center-coverage-icon" 
            header={"Your Coverage Starts " + date} 
            message="We're shipping your devices. Install them once they're delivered to start tracking your vehicles." 
            onClick={null} 
            priority={5} 
            display='none'
            id="CoverageStarts"/>
    )
}

const displayTypes = [
    "chevron",
    "x",
]

export default ({icon, header, message, onClick = () => { }, display = "chevron", id}: DashboardNotificationProps) => {
    return (
        <>
            <div className="flexible-container notification-container" onClick={onClick} id={id}>
                <div className="row">
                    <div className="col-sm-1 icon-column">
                        <div className={icon} />
                    </div>
                    <div className="col-sm-9">
                        <p className="text-left notification-header">{header}</p>
                        <p className="text-left notification-message">{message}</p>
                    </div>
                    {displayTypes.indexOf(display) === 0 &&
                    <div className="col-sm-1 chevron-column">
                        <div className="row">
                            <span className='icon-chevron-right notification-chevron' />
                        </div>
                    </div>}
                    {displayTypes.indexOf(display) === 1 &&
                    <div className="col-sm-1 chevron-column">
                        <div className="row">
                            <span className='icon-close notification-chevron' />
                        </div>
                    </div>}
                </div>
            </div>
        </>
    )
}