declare global {
    interface Window {GdkNPM: any}
}

function initGdkNPM(component: string, versions: Array<{ version: string, release: string }>, jsClass: any):void {
    if(!window.GdkNPM) {
        window.GdkNPM = {
            about: {}
        };
    }

    if(!component) {
        return;
    }

    const setVersion = (releases, latestVersion, latestRelease) => {
        window.GdkNPM.about[component] = {
            "version": latestVersion,
            "release": latestRelease,
            "versions": releases,
            [component]: jsClass
        }
    }

    if(!window.GdkNPM.about[component]) {
        setVersion(versions.slice(), versions[0].version, versions[0].release);
    } else if(window.GdkNPM.about[component] && versions[0].version !== window.GdkNPM.about[component].version) {
        setVersion(versions.slice(), versions[0].version, versions[0].release);
    }
}

export default {initGdkNPM};