import React, { ReactFragment } from "react";
import { ReactElement } from "react";
import * as DashboardStore from '../../store/DashboardStore';
import { connect } from 'react-redux';
import DashboardNotification from "../common/DashboardNotification";
import { createMap, createMapMarker, generateMapIconsWithId } from "./CustomerDashboard";
import DanlawVehicle from "../../models/DanlawVehicle";
import { LoggedInUser } from "../../models/LoggedInUser";
import Button, { ButtonStyles } from "../common/Button";
import * as Notifications from "../common/DashboardNotification";
import { setCustomerDashboardPanel } from "../../helpers/navigationHelpers";
import { VehicleChipMessages } from "../common/DashboardVehicle";
import { PolicyInfo } from "../../models/PolicyInfo";
import DeviceShipment from "../../models/DeviceShipment";

interface DashboardTabProps {
    vehicles: DanlawVehicle[];
    loggedInUser: LoggedInUser;
    policy: PolicyInfo;
    shipments: DeviceShipment[];
}

interface DashboardTabState {
    dashboardMap: any;
    vehicles: DanlawVehicle[];
    shipments: DeviceShipment[];
    policy: PolicyInfo;
    notifications: ReactElement[];
}


export class DashboardTab extends React.Component<DashboardTabProps, DashboardTabState> {
    interval!: NodeJS.Timeout;
    vehiclesAndShipmentsInterval!: NodeJS.Timeout;
    constructor(props: DashboardTabProps) {
        super(props);
        this.state = {
            dashboardMap: undefined,
            vehicles: this.props.vehicles,
            shipments: this.props.shipments,
            policy: this.props.policy,
            notifications: []
        };
    }

    componentDidMount(): void {
        const { vehicles } = this.props;        
        this.setState({
            dashboardMap: createMap('dashboard-map', '2111b9d1-66dd-4b4a-afac-30755940c153', undefined, 5, false, false, false)
        })
        let testDate = Date.now();
        //testDate -= (1000*60*60*24*8);
        localStorage.setItem("lastUpdate", testDate.toString());
        this.interval = setInterval(this.updateLastUpdatedTime.bind(this), 60000);
        this.vehiclesAndShipmentsInterval = setInterval(this.setVehiclesAndShipmentsStateForCustomerView.bind(this), 1000);
        let closeIcons = document.getElementsByClassName("icon-close");
        Array.from(closeIcons).forEach((element) => {
            element.addEventListener("click", (event) => {
                const target = event?.target as HTMLElement;
                let targetId = target?.parentElement?.parentElement?.parentElement?.parentElement?.id;
                this.state.notifications.forEach((notification: ReactElement) => {
                    if (targetId == notification.props.id) {
                        let newNotifs = this.state.notifications.filter((notification: ReactElement) => notification.props.id != targetId);
                        console.dir(notification);
                        this.setState({
                            notifications: newNotifs
                        });
                    }
                });
            });
        });
    }

    setVehiclesAndShipmentsStateForCustomerView() {
        if (!this.state.vehicles) {
            this.setState({vehicles: this.props.vehicles});
        }
        if (!this.state.shipments) {
            this.setState({shipments: this.props.shipments});
        }
        if(!this.state.policy) {
            this.setState({policy: this.props.policy});
        }
        if (this.state.shipments && this.state.vehicles && this.state.policy) {
            clearInterval(this.vehiclesAndShipmentsInterval);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        clearInterval(this.vehiclesAndShipmentsInterval);
    }

    renderNotifications(): ReactElement {
        const { vehicles, shipments, policy } = this.props;
        const { notifications } = this.state;

        if (notifications.length == 0) {
            if (vehicles) {
                vehicles?.forEach((vehicle: DanlawVehicle) => {
                    if (vehicle?.chipMessage === VehicleChipMessages.BeingTowed) {
                        notifications.push(Notifications.BeingTowed());
                    } else if (vehicle?.chipMessage === VehicleChipMessages.NotConnected) {
                        notifications.push(Notifications.Reconnect(vehicle));
                    }else if (vehicle?.deviceInfo?.deviceStatus === "Installed") {
                        if (Notifications.DevicesConnected(vehicle) != <></>) {
                            notifications.push(Notifications.DevicesConnected(vehicle));
                        }
                    }
                });
            }
            if(shipments && shipments.length > 0) {
                shipments?.forEach((shipment: DeviceShipment) => {
                    if (shipment?.shipmentStatus === "In Transit") {
                        notifications.push(Notifications.TrackShipment());
                    } else if (shipment?.shipmentStatus === "Delivered") {
                        notifications.push(Notifications.InstallDevice());
                    }
                });
            }

            if (!vehicles && shipments && policy && policy.vehicles) {
                let date = policy.vehicles[0].enrollmentEffectiveDate;

                if(Date.parse(date) > Date.now()) {
                    notifications.push(Notifications.CoverageStarts(date));
                }
            }

            if (notifications.length < 1) {
                notifications.push(Notifications.CaughtUp());
            }
        }
        notifications.sort((a, b) => a.props.priority > b.props.priority ? 1 : -1);

        if (notifications.length > this.state.notifications.length) {
            this.setState({
                notifications: notifications
            });
        }
        return (
            <>
                {notifications?.slice(0, 4).map((notification: ReactFragment) => notification)}
            </>
        )
    }

    renderVehicleStatus(): ReactElement[] {
        const {vehicles} = this.props;

        let movingCount = 0;
        let idlingCount = 0;
        let parkedCount = 0;
        let towedCount = 0;
        let disconnectedCount = 0;
        let elementList: ReactElement[] = [];

        if (vehicles) {
            vehicles.forEach((vehicle: DanlawVehicle) => {
                if (vehicle?.chipMessage === VehicleChipMessages.Moving) {
                    movingCount++;
                } else if (vehicle?.chipMessage === VehicleChipMessages.Idling) {
                    idlingCount++;
                } else if (vehicle?.chipMessage === VehicleChipMessages.Parked) {
                    parkedCount++;
                } else if (vehicle?.chipMessage === VehicleChipMessages.BeingTowed) {
                    towedCount++;
                } else if (vehicle?.chipMessage === VehicleChipMessages.NotConnected) {
                    disconnectedCount++;
                }
            });

            let counts = [
                [movingCount, VehicleChipMessages.Moving, "vehicle-status-moving-icon"], 
                [idlingCount, VehicleChipMessages.Idling, "vehicle-status-idling-icon"], 
                [parkedCount, VehicleChipMessages.Parked, "vehicle-status-parked-icon"], 
                [towedCount, VehicleChipMessages.BeingTowed, "vehicle-status-towed-icon"], 
                [disconnectedCount, VehicleChipMessages.NotConnected, "vehicle-status-disconnected-icon"]
            ];

            let filteredCounts = counts.filter((count) => count[0] as number > 0).sort((a, b) => a[0] < b[0] ? 1 : -1);

            filteredCounts.forEach((count) => {
                elementList.push(
                    <><div className="vehicle-status-div"><div className={(count[2] as string) + " vehicle-status-icon"} /><p className="vehicle-status-text">{count[0] + " " + count[1] + " "}</p></div></>
                );
            });
        }
        return elementList;
    }

    getLastUpdateTimeText(): string {
        let lastUpdated = localStorage.getItem("lastUpdate");
        if (lastUpdated) {
            let lastUpdatedDate = new Date(parseInt(lastUpdated));
            let timeText = "Just now";
            let diffTime = Math.abs(Date.now() - parseInt(lastUpdated));
            let diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); 

            if(diffDays >= 7) {
                let dateString = lastUpdatedDate.toLocaleDateString();
                let timeString = lastUpdatedDate.toLocaleTimeString("en-US", {hour: "numeric", minute: "numeric"});
                timeText = dateString + " at " + timeString;
            }
            else if (diffDays < 7 && diffDays > 1) {
                let dateString = new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(lastUpdatedDate);
                let timeString = lastUpdatedDate.toLocaleTimeString("en-US", {hour: "numeric", minute: "numeric"});
                timeText = dateString + " at " + timeString;

            }
            else if (diffDays == 1) {
                let timeString = lastUpdatedDate.toLocaleTimeString("en-US", {hour: "numeric", minute: "numeric"});
                timeText = "Yesterday at " + timeString;
            }
            else if(diffTime >= 60000) {
                let timeString = lastUpdatedDate.toLocaleTimeString("en-US", {hour: "numeric", minute: "numeric"});
                timeText = "Today at " + timeString;
            }

            return "Last Updated: " + timeText;
        }

        return "";
    }

    updateLastUpdatedTime(): void {
        let timeElement = document.getElementById("lastUpdatedTime");
        let timeText = this.getLastUpdateTimeText();

        if (timeElement) {
            timeElement.innerText = timeText;
        }
    }

    createMapAndPlaceIcons(): void {
        const {vehicles} = this.props;
        const { dashboardMap } = this.state;

        if (vehicles) {
            for (let i = 1; i <= vehicles.length; i++) {
                let svgIcon = document.getElementById("svgIconDashboard-" + i) as HTMLElement;
                if (svgIcon && dashboardMap.getMarkers().length == 0) {
                    createMapMarker(dashboardMap, [vehicles[i-1].latLong?.longitude,vehicles[i-1].latLong?.latitude], { element: svgIcon });
                }
            }
            dashboardMap?.fitToMarkers({duration: 0});
        }
    }
    
    render(): ReactElement {
        const {vehicles, loggedInUser, policy} = this.props;
        //let firstName = loggedInUser.account.name.split(" ")[1];
        let firstName = policy?.policyHolder.split(" ")[0];

        return (
            <>
            <div className="container dashboard-container" id="dashboard-container" style={{display:"block"}}>
                <div className="row dashboard-header-row"> 
                    <div className="col-md-12 dashboard-header-col">
                        <div className="dashboard-welcome-header">
                            <p className="drive-easy-welcome">
                                Welcome to DriveEasy Pro, {firstName}.
                            </p>
                            <p id="lastUpdatedTime">{this.getLastUpdateTimeText()}</p>
                        </div>
                    </div>
                </div>
                <div className="row dashboard-row">
                    <div className="col-md-4 dashboard-left-column">
                        <div className="row">
                            <div className="flexible-container dashboard-notification-container">
                                <p className="dashboard-container-header">Notification Center</p>
                                {this.renderNotifications()}
                            </div>
                        </div>
                        <div className="row">
                            <div className="flexible-container dashboard-coming-soon-container">
                                <div className="col-sm-2 coming-soon-icon-col">
                                    <div className="dashboard-coming-soon-icon"></div>
                                </div>
                                <div className="col-sm-10">
                                    <p className="more-coming-soon-text">More Coming Soon!</p>
                                    <ul className="list list--unordered coming-soon-list">
                                        <li className="more-coming-soon-list-text">Vehicle Health Statuses</li>
                                        <li className="more-coming-soon-list-text">Track Driving Behaviors</li>
                                        <li className="more-coming-soon-list-text">Trips History and more!</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="flexible-container dashboard-map-container" >
                            <div className="container">
                                <div className="row" onClick={() => setCustomerDashboardPanel("vehicles")}>
                                    <div className="col-sm-11 track-vehicles-container">
                                        <p className="dashboard-container-header">Track Vehicles</p>
                                    </div>
                                    <div className="col-sm-1 track-vehicles-chevron-container">
                                        <span className='icon-chevron-right track-vehicles-chevron' />
                                    </div>
                                </div>
                                <div className="row map-row">
                                    <div id="dashboard-map-container" style={{ width:'560px', height: '480px', position: 'absolute' }}>
                                    <div id="dashboard-map" style={{ height: '680px', position: 'absolute', width: '590px' }} onClick={() => setCustomerDashboardPanel("vehicles")}/>
                                    {this.createMapAndPlaceIcons()}
                                    {/*(!vehicles || vehicles?.length == 0)  && <div className="static-dashboard-map-container">
                                        <img className="static-dashboard-map" width="560px" height="480px" src="https://api.radar.io/maps/static?width=560&height=480&center=38.96119,-77.09000&zoom=10&style=2111b9d1-66dd-4b4a-afac-30755940c153&scale=2&publishableKey=prj_test_pk_442478703bf935fd048827d898d348472d862c53" />
                                            <div className="flexible-container install-devices-popup">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-sm-1 icon-column">
                                                            <div className="notification-center-vehicle-icon" />
                                                        </div>
                                                        <div className="col-sm-10 dashboard-popup-message">
                                                            <div className="row"><p className="dashboard-popup-header">Install Your Devices to Track Your Vehicles</p></div>
                                                            <div className="row"><p className="dashboard-popup-body">Track your vehicle(s) on the map in real-time after you finish installing your device(s). For device details and install guides, view the Devices page.</p></div>
                                                            <div className="row"><p className="dashboard-popup-link" onClick={() => setCustomerDashboardPanel("devices")}>View Devices Page</p></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>*/
                                    }
                                    </div>
                                </div>
                                <div className="row" onClick={() => setCustomerDashboardPanel("vehicles")}>
                                    <div className="row under-map-display-container">
                                        {this.renderVehicleStatus()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="flexible-container dashboard-coming-soon-container-mobile">
                                <div className="col-sm-2 coming-soon-icon-col">
                                    <div className="dashboard-coming-soon-icon"></div>
                                </div>
                                <div className="col-sm-10">
                                    <p className="more-coming-soon-text">More Coming Soon!</p>
                                    <ul className="list list--unordered coming-soon-list">
                                        <li className="more-coming-soon-list-text">Vehicle Health Statuses</li>
                                        <li className="more-coming-soon-list-text">Track Driving Behaviors</li>
                                        <li className="more-coming-soon-list-text">Trips History and more!</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {generateMapIconsWithId(vehicles, "svgIconDashboard-").map((icon: ReactElement) => icon)}
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = (state: any, ownProps: any) => ({
    selectedVehicle: state.dshs.selectedVehicle,
    popups: state.dshs.popups,
    loggedInUser: state.loggedInUser,
    policy: state.dshs.policy,
    shipments: state.dshs.shipments,
});

export default connect(
    mapStateToProps,
    DashboardStore.actionCreators,
)(DashboardTab as any);