import baseComponent from "@gdk/base-component";
import Version from "@gdk/version";
import { IBaseComponentOptions } from "@gdk/base-component";

const component = "Tooltip";
const versions = [
    { version: "3.3.0", release: "8.03.23"},
    { version: "3.2.0", release: "5.25.23"},
    { version: "3.1.0", release: "3.31.23"},
    { version: "3.0.5", release: "3.17.23"},
    { version: "3.0.4", release: "2.24.23"}
];

// Trick to use array as type but also to make it reusable for type checks at runtime: https://stackoverflow.com/a/59857409
const wrapperTagArr = ["div", "span"] as const;
type WrapperTag = typeof wrapperTagArr[number];

const validateSettings = [
    {
        setting                 :   "content",
        isRequired              :   true,
        validate                :   "type",
        possibleValues          :   ["string","object"],
        errorMessage            :   ["GDK tooltip : Content must be defined and set to a DOM selector or Node"]
    },
    {
        setting                 :   "tooltipText",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["string"],
        errorMessage            :   ["GDK tooltip : tooltipText must be set to a string"]
    },
    {
        setting: "tooltipOpen",
        isRequired: false,
        validate: "type",
        possibleValues: ["function"],
        errorMessage: ["GDK tooltip : tooltipOpen must be defined and set function"]
    },
    {
        setting: "tooltipClose",
        isRequired: false,
        validate: "type",
        possibleValues: ["function"],
        errorMessage: ["GDK tooltip : tooltipClose must be defined and set function"]
    },
    {
        setting: "wrapperTag",
        isRequired: false,
        validate: "type",
        possibleValues: ["string"],
        errorMessage: ["GDK tooltip : wrapperTag must be set to a string"]
    }
];

export interface ITooltip extends IBaseComponentOptions {
    tooltipText?: string;
    tooltipOpen?: () => void;
    tooltipClose?: () => void;
    wrapperTag?: WrapperTag
}

/**
 * @desc GDK Tooltip JavaScript Class
 *
 * @example <caption>JS Instantiation</caption>
 * var tooltip1 = new GDK.Tooltip({
 *  content : "#tooltip-trigger-1",
 *  toolTipOpen : function(tooltip) {
 *    console.log("Tooltip is open");
 *    console.log(tooltip);
 *  },
 *  toolTipClose : function(tooltip) {
 *    console.log("Tooltip has been closed");
 *    console.log(tooltip);
 * 	}
 * });
 * 
 * @example <caption>JS Examples - Create tooltip through JS setting</caption>
 * var tooltip2 = new GDK.Tooltip({
 *  content : "#tooltip-trigger-2",
 *  tooltipText: "Tooltip content",
 *  wrapperTag: "span"
 * });
 *
 * @example <caption>JS Examples - External link alternative trigger</caption>
 * document.getElementById("alternative-trigger-1").addEventListener('click', function(){
 *  window.open(yourURL, name, specs);
 *  //EXAMPLE - window.open("https://www.geico.com/other/rememberme/", "rememberme", "menubar=0,resizable=1,scrollbars=1,width=400,height=575")
 * });
 */
class GdkTooltip {
    _internalVars: {
        contentType: "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function";
        tooltipCloseElementClass: string; margin: number;
        closeBtn: any;
        tooltip: any;
        wrapper: any;
        timeout: any;
        tooltipTriggerElementClass: string;
        breakpoint: number;
        navBarBreakpoint: number;
        tooltipActiveClass: string;
        node: any;
        delay: number;
        wrapperElementId: string;
        triggerOffset: any;
        wrapperTag: WrapperTag
    };

    readonly _defaults: object;

    readonly _options: ITooltip;

    /**
     * Refer to the design kit section of this component for JS examples and setting details.
     * @param {string, Object} content
     *  A reference to the html tooltip trigger's node
     *
     * @param {string} [tooltipText]
     * Used to invoke a dynamic Tooltip allowing the ability to skip creating the markup for one
     *
     * @param {function} [tooltipOpen]
     * A callback function triggered when a tooltip is displayed
     *
     * @param {function} [tooltipClose]
     * A callback function triggered when a tooltip is closed manually
     * 
     * @param {string} [wrapperTag]
     * A wrapper HTML tag that will set be used as the container for the tooltip. Use "span" when the tooltip is adjacent to heading.
     *
     */
    constructor(options: ITooltip) {
        /**
         * @ignore
         */
        this._internalVars = {
            contentType: null,
            node: null,//used for content item
            tooltip : null,
            triggerOffset : null,
            timeout : null,
            delay : 300, // delay 0.3 seconds
            breakpoint : 768,
            navBarBreakpoint: 1000,
            margin : 12,
            wrapperElementId : "wrapper",
            tooltipTriggerElementClass : "tooltip-trigger",
            tooltipCloseElementClass : "icon-close",
            tooltipActiveClass : "tooltip--active",
            wrapper : null,
            closeBtn : null,
            wrapperTag: "span",
        };

        //options with defaults set
        /**
         * @ignore
         */
        this._defaults = {
            tooltipText: null
        };

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            /**
             * @ignore
             */
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if (baseComponent.validateSettings(this._options, validateSettings)) {
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
        }
    }

    //Public Methods

    /**
     * @desc Removes the node from the dom and any events attached
     */
    destroy(): void {
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);
        this._internalVars.tooltip.parentNode.removeChild(this._internalVars.tooltip);
        //a little garbage collection
        for (const variableKey in this) {
            if (Object.prototype.hasOwnProperty.call(this, variableKey)) {
                delete this[variableKey];
            }
        }
    }

}

// Private Methods
/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars(): void {
    //determine the type of content passed in
    if (this._internalVars.contentType === "string") {
        this._internalVars.node = document.querySelector(this._options.content);
    } else if (this._internalVars.contentType === "domNode") {
        this._internalVars.node = this._options.content;
    }
    if (this._options.wrapperTag) {
        if (!wrapperTagArr.includes(this._options.wrapperTag)) {
            console.warn(`wrapperTag props must be of type WrapperTag. ${ wrapperTagArr.join(" | ") }`);
        } else {
            this._internalVars.wrapperTag = this._options.wrapperTag;
        }
    }

    const tooltipId = this._internalVars.node.getAttribute("data-tooltip-view");
    // If they don't provide a tooltipText we are assuming they have their own tooltip
    const tooltipExists = document.querySelector(`#${tooltipId}`) || !this._options.tooltipText;

    if (tooltipExists) {
        this._internalVars.tooltip = document.querySelector(`#${this._internalVars.node.getAttribute("data-tooltip-view")}`);
        this._internalVars.node.parentNode.insertBefore(this._internalVars.tooltip, this._internalVars.node.nextSibling);
    } else {
        buildNewTooltip.call(this);
    }

    this._internalVars.wrapper = document.querySelector(`#${this._internalVars.wrapperElementId}`);
    this._internalVars.closeBtn = this._internalVars.tooltip.querySelector(`.${this._internalVars.tooltipCloseElementClass}`);
    this._internalVars.tooltipClass = "tooltip";
}

/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents(): void {
    if ("ontouchstart" in document.documentElement) {
        this._internalVars.node.addEventListener("click", showTooltip.bind(this));
        this._internalVars.node.addEventListener("mouseover", showTooltip.bind(this));
        this._internalVars.closeBtn.addEventListener("click", closeBtnClickHandler.bind(this));
    } else {
        this._internalVars.node.addEventListener("mouseenter", showTooltip.bind(this));
        this._internalVars.node.addEventListener("click", function(e: Event) {e.preventDefault();});
        this._internalVars.node.addEventListener("focus", showTooltip.bind(this));
        this._internalVars.node.addEventListener("keydown", shiftTab.bind(this));
        this._internalVars.closeBtn.addEventListener("click", closeBtnClickHandler.bind(this));
        this._internalVars.closeBtn.addEventListener("blur", closeBtnClickHandler.bind(this));
    }
}

function shiftTab(e: KeyboardEvent): void {
    if (e.key == "9" && e.shiftKey) {
        hideTooltip.call(this);
    }
}

/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents(): void {
    if ("ontouchstart" in document.documentElement) {
        this._internalVars.node.removeEventListener("click", showTooltip.bind(this));
        this._internalVars.node.removeEventListener("mouseover", showTooltip.bind(this));
        this._internalVars.closeBtn.removeEventListener("click", closeBtnClickHandler.bind(this));
    } else {
        this._internalVars.node.removeEventListener("mouseenter", showTooltip.bind(this));
        this._internalVars.closeBtn.removeEventListener("click", closeBtnClickHandler.bind(this));
        this._internalVars.node.removeEventListener("click", function(e: Event) {e.preventDefault();});
        this._internalVars.node.removeEventListener("focus", showTooltip.bind(this));
        this._internalVars.node.removeEventListener("keydown", shiftTab.bind(this));
        this._internalVars.closeBtn.removeEventListener("blur", closeBtnClickHandler.bind(this));
    }
}

/**
 * closeBtnClickHandler()
 * catches click on close button
 */
function closeBtnClickHandler(e: Event): void {
    e.preventDefault();
    hideTooltip.call(this);

    if (this._options.tooltipClose) {
        const target = e.currentTarget as HTMLElement;
        const tooltip = target.parentNode;
        this._options.tooltipClose(tooltip);
    }
}

/**
 * buildNewTooltip()
 * use to dynamically create the tooltip
 */
function buildNewTooltip(): void {
    const tooltipId = this._internalVars.node.getAttribute("data-tooltip-view");
    const wrapperTag = this._internalVars.wrapperTag; // equal to div or span
    const dynamicTooltip = document.createElement(wrapperTag);
    dynamicTooltip.setAttribute("id", tooltipId);
    dynamicTooltip.classList.add("tooltip");
    dynamicTooltip.innerHTML = `<${wrapperTag} tabindex='0'>${this._options.tooltipText}</${wrapperTag}><button class='icon-close' aria-label='Close tooltip'></button>`;

    this._internalVars.node.parentNode.insertBefore(dynamicTooltip, this._internalVars.node.nextSibling);
    this._internalVars.tooltip = document.querySelector(`#${tooltipId}`);
}

/**
 * showTooltip()
 * show tooltip on hover
 */
function showTooltip(e: Event): void {
    e.preventDefault();

    // prep tooltip

    const tooltipId = this._internalVars.node.getAttribute("data-tooltip-view");
    const tooltip = document.querySelector(`#${tooltipId}`);
    if (tooltip !== this._internalVars.node.nextSibling) {
        this._internalVars.node.parentNode.insertBefore(tooltip, this._internalVars.node.nextSibling);
        this._internalVars.tooltip = tooltip;
    }

    try {
        hideTooltip.call(this);
    } catch (err) {
        // clear existing tt
    }

    clearTimeout(this._internalVars.timeout);
    const self = e.currentTarget as HTMLElement;

    self.classList.add(this._internalVars.tooltipActiveClass);

    this._internalVars.activationArea = self;

    self.addEventListener("mouseleave", settimeout.bind(this));

    this._internalVars.tooltip.addEventListener("mouseleave", settimeout.bind(this));

    this._internalVars.tooltip.addEventListener("mouseover", cleartimeout.bind(this));

    this._internalVars.triggerOffset = self.getBoundingClientRect();

    setTimeout(() => {
        if (self.classList.contains("tooltip--active")) {
            this._internalVars.tooltip.style.display = "block";
            //position the tooltip after setting to block
            positionTooltip.call(this);
            //set opacity and let css transition fade it in
            this._internalVars.tooltip.style.opacity = 1;
        }
    },this._internalVars.delay);

    if (this._options.tooltipOpen) {
        const target = e.currentTarget as HTMLElement;
        const triggerElement = target.parentNode;
        const tooltip = triggerElement.querySelector(`.${this._internalVars.tooltipClass}`);
        this._options.tooltipOpen(tooltip);
    }
}

/**
 * cleartimeout()
 * clears set timeout
 */
function cleartimeout(): void {
    clearTimeout(this._internalVars.timeout);
}

/**
 * settimeout()
 * sets a timeout to hide the tooltip
 */
function settimeout(e: Event): void {
    if (document.activeElement != e.currentTarget) {
        clearTimeout(this._internalVars.timeout);
        this._internalVars.node.classList.remove(this._internalVars.tooltipActiveClass);
        this._internalVars.timeout = setTimeout(() => {
            hideTooltip.call(this);
        }, 200);
        // this._internalVars.timeout = hideTooltip.call(this);
    }
}

/**
 * hideTooltip()
 * hides the tooltip
 */
function hideTooltip(): void {
    this._internalVars.tooltip.style.opacity = 0;

    setTimeout(() => {
        this._internalVars.tooltip.style.display = "none";
        this._internalVars.tooltip.style.bottom = `auto`;
        this._internalVars.tooltip.style.top = `auto`;
    },this._internalVars.delay);
}

/**
 * positionTooltip()
 * sets the position for the tooltip depending on trigger position
 */
function positionTooltip(): void {
    function clearArrows(): void {
        this._internalVars.tooltip.classList.remove("right-arrow");
        this._internalVars.tooltip.classList.remove("bottom-arrow");
        this._internalVars.tooltip.classList.remove("left-arrow");
        this._internalVars.tooltip.classList.remove("top-arrow");
    }

    clearArrows.call(this);


    this._internalVars.tooltip.style.right = `auto`;
    this._internalVars.tooltip.style.left = `auto`;
    this._internalVars.tooltip.style.top = `auto`;
    this._internalVars.tooltip.style.bottom = `auto`;

    let isLeftPosition = false;
    let isRightPosition = false;

    const triggerRectangle = this._internalVars.node.getBoundingClientRect();
    const windowWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    function setToBottomPosition(): void {
        clearArrows.call(this);
        this._internalVars.tooltip.style.top = `auto`;
        this._internalVars.tooltip.style.bottom  = `40px`;
        this._internalVars.tooltip.classList.add("bottom-arrow");
    } 

    function moveToView(): void {
        const tooltipRectangle = this._internalVars.tooltip.getBoundingClientRect();
        if (tooltipRectangle.right > windowWidth) {
            this._internalVars.tooltip.style.right = `${triggerRectangle.left - windowWidth + 30}px`;
            this._internalVars.tooltip.style.left = `auto`;
            if (isLeftPosition || isRightPosition) {
                setToBottomPosition.call(this);
            }
        }
        if (tooltipRectangle.left < 0) {
            this._internalVars.tooltip.style.right = `auto`;
            this._internalVars.tooltip.style.left = `-${triggerRectangle.left - 20}px`;
            if (isLeftPosition || isRightPosition) {
                setToBottomPosition.call(this);
            }
        }
    }

    if ((Number(triggerRectangle.top) - Number(this._internalVars.tooltip.offsetHeight/2)) < 100) { 
        //if top of tooltip is out of view, set to bottom position
        this._internalVars.tooltip.style.top  = `44px`;
        this._internalVars.tooltip.style.left = `-123px`;
        this._internalVars.tooltip.classList.add("top-arrow");
        moveToView.call(this); //check if sides are in view
    } else if ((Number(triggerRectangle.top) + Number(this._internalVars.tooltip.offsetHeight/2)) > (window.innerHeight - 25)) {
        //if top of tooltip is out of view, set to bottom position
        this._internalVars.tooltip.style.left = `-123px`;
        setToBottomPosition.call(this);
        moveToView.call(this); //check if sides are in view
    } else {  
        if ((Number(triggerRectangle.right) + 300) < windowWidth) { 
            //if right of tooltip is out of view, set to right position
            this._internalVars.tooltip.style.top = `${(this._internalVars.node.offsetHeight/2) - (this._internalVars.tooltip.offsetHeight/2) + 2}px`;     
            this._internalVars.tooltip.style.left = `44px`;
            this._internalVars.tooltip.classList.add("left-arrow");
            isLeftPosition = true;
            moveToView.call(this); //check if sides are in view
        } else { 
            //if tooltip is in full view, set to left position
            this._internalVars.tooltip.style.top = `${(this._internalVars.node.offsetHeight/2) - (this._internalVars.tooltip.offsetHeight/2)  + 2}px`;     
            this._internalVars.tooltip.style.left = `-289px`;
            this._internalVars.tooltip.classList.add("right-arrow");
            isRightPosition = true;
            moveToView.call(this); //check if sides are in view
        }
    }
}

Version.initGdkNPM(component, versions, GdkTooltip);

export { GdkTooltip };