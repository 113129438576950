import Version from "@gdk/version";
import baseComponent, {IBaseComponentOptions} from "@gdk/base-component";
import $ from "jquery";

const component = "Footer";
const versions = [
    { version: "2.0.4", release: "1.20.23"},
    { version: "2.0.3", release: "12.16.22"},
    { version: "2.0.2", release: "10.28.22"},
    { version: "2.0.1", release: "10.14.22"},
    { version: "2.0.0", release: "7.22.22"}
];

const validateSettings = [
    {
        setting:  "content",
        isRequired:  true,
        validate:  "type",
        possibleValues:  ["string", "object"],
        errorMessage:  ["GDK Footer :  Content must be defined and set to a DOM selector or Node"]
    }
];

export {IBaseComponentOptions};

/**
 * @desc GDK Footer JavaScript Class
 *
 * @example <caption>JS Instantiation</caption>
 * var footer = new GDK.Footer({
 *    "content" : "#primary-footer"
 * });
 */
class GdkFooter {
    _internalVars:  {
        contentType:  string | HTMLElement;
        node:  HTMLElement;
    };

    readonly _defaults:  object;

    readonly _options:  IBaseComponentOptions;

    /**
     * @param {Object} options Settings for the instantiation.
     *
     * @param {string|HTMLElement} options.content A reference to the component node
     */
    constructor(options:  IBaseComponentOptions) {
        /**
         * @type {Object} _internalVars
         */
        this._internalVars = {
            node:  null,
            contentType:  null
        };

        //options with defaults set
        this._defaults = {};

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            /**
             * @type {Object}
             */
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }


        //if the required options are valid set up the environment
        if (baseComponent.validateSettings(this._options, validateSettings)) {
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
            setAriaAttrs.call(this);
            setAccordionState.call(this);
            setYear.call(this);
        }
    }

    /**
     * @desc Removes the node from the dom and any events attached
     */
    destroy(): void {
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (const variableKey in this) {
            if (Object.prototype.hasOwnProperty.call(this, variableKey)) {
                delete this[variableKey];
            }
        }
    }

}

// Private Methods

function setLocalVars(): void {
    if (this._internalVars.contentType === "string") {
        this._internalVars.node = document.querySelector(this._options.content);
    } else if (this._internalVars.contentType === "domNode") {
        this._internalVars.node = this._options.content;
    }

    this._internalVars.copyRight = this._internalVars.node.querySelector("#footerDate");
    this._internalVars.footerNewYear = new Date();
    this._internalVars.footerCurrentYear = this._internalVars.footerNewYear.getFullYear();
    this._internalVars.mobileHeadlines = this._internalVars.node.querySelectorAll(".mobile-headline");
    this._internalVars.listItems = this._internalVars.node.querySelectorAll(".list-items");
    this._internalVars.clickHandler = toggleAccordion.bind(this);
    this._internalVars.resizeHandler = setAccordionState.bind(this);
}

function setEvents(): void {
    if (this._internalVars.mobileHeadlines.length > 0) {
        Array.prototype.forEach.call(this._internalVars.mobileHeadlines, (headline: HTMLElement) => {
            headline.addEventListener("click", this._internalVars.clickHandler);
            headline.addEventListener("keypress", this._internalVars.clickHandler);
        });
    }
    
    window.addEventListener("resize", this._internalVars.resizeHandler);
}

function removeEvents(): void {
    if (this._internalVars.mobileHeadlines.length > 0) {
        Array.prototype.forEach.call(this._internalVars.mobileHeadlines, (headline: HTMLElement) => {
            headline.removeEventListener("click", this._internalVars.clickHandler);
            headline.removeEventListener("keypress", this._internalVars.clickHandler);
        });
    }

    window.removeEventListener("resize", this._internalVars.resizeHandler);
}

function setYear():void {
    this._internalVars.copyRight.innerHTML = String(this._internalVars.footerCurrentYear);
}

function setAccordionState() :void {
    if (this._internalVars.listItems.length > 0 && this._internalVars.mobileHeadlines.length > 0) {
        if (window.innerWidth < 1016) {
            Array.prototype.forEach.call(this._internalVars.listItems, (listItem: HTMLElement) => {
                listItem.setAttribute("aria-hidden", "true");
                $(listItem).slideUp();
            });

            Array.prototype.forEach.call(this._internalVars.mobileHeadlines, (headline: HTMLElement) => {
                headline.setAttribute("aria-expanded", "false");
                headline.classList.remove("open");
            });
        } else {
            Array.prototype.forEach.call(this._internalVars.listItems, (listItem: HTMLElement) => {
                listItem.setAttribute("aria-hidden", "false");
                $(listItem).slideDown();
            });

            Array.prototype.forEach.call(this._internalVars.mobileHeadlines, (headline: HTMLElement) => {
                headline.setAttribute("aria-expanded", "true");
            });
        }
    }
}

function setAriaAttrs() :void {
    if (this._internalVars.mobileHeadlines.length > 0) {
        Array.prototype.forEach.call(this._internalVars.mobileHeadlines, (headline: HTMLElement) => {
            headline.setAttribute("role", "button");
            headline.setAttribute("tabIndex", "0");
        });
    }
}

function collapseAccordions() :void {
    Array.prototype.forEach.call(this._internalVars.listItems, (listItem: HTMLElement) => {
        $(listItem).slideUp();
        listItem.setAttribute("aria-hidden", "true");
    });

    Array.prototype.forEach.call(this._internalVars.mobileHeadlines, (headline: HTMLElement) => {
        headline.setAttribute("aria-expanded", "false");
        headline.classList.remove("open");
    });
}

function toggleAccordion(e: {type:string; keyCode:number; currentTarget:HTMLElement;}) :void {
    console.log(e);
    if (e.type === "keypress" && e.keyCode !== 13) {
        return;
    }
    
    const clickedHeadline: HTMLElement = e.currentTarget;
    const wrapper: HTMLElement = clickedHeadline.parentElement.parentElement;
    const accordion: HTMLElement = wrapper.querySelector(".list-items");
    
    if (!clickedHeadline.classList.contains("open")) {
        collapseAccordions.call(this);
        clickedHeadline.classList.add("open");
        $(accordion).slideDown();
        accordion.setAttribute("aria-hidden", "false");
        clickedHeadline.setAttribute("aria-expanded", "true");
    } else {
        collapseAccordions.call(this);
    }
}

Version.initGdkNPM(component, versions, GdkFooter);

export {GdkFooter};