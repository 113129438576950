// base interface to encapsulate the content declaration with is the same for most
// GDK components
export interface IBaseComponentOptions {
    /**
     * A reference to the component node to toggle (DOM selector string | DOM node)
     */
    content: string | Element;
}

let gdkBaseComponent = {
    /**
     * validateSettings()
     * Validates all content settings
     */

    validateSettings: function (currentOptions: any, validationRules: any) {
        let valid: boolean = true;

        Array.prototype.forEach.call(validationRules, function(el, i){

            let setting = currentOptions[el.setting];

            if(el.isRequired === true){

                //if a required field is not set retun false
                if(gdkBaseComponent.validateRequiredSettings(el, currentOptions)){

                    if(!gdkBaseComponent.validateSettingValues(setting, el.validate, el.possibleValues, el.errorMessage)){
                        valid = false;
                        return false;
                    }

                }else{
                    valid = false;
                    return false;
                }

            }else{

                //if is a setting is not required but its set we need to validate it
                if( setting ){
                    if(!gdkBaseComponent.validateSettingValues(setting, el.validate, el.possibleValues, el.errorMessage)){
                        valid = false;
                        return false;
                    }
                }

            }

        });

        if(valid){
            return true;
        }
    },


    validateRequiredSettings : function(el, currentOptions) {

        let setting = currentOptions[el.setting];

        try {

            if( setting !== undefined ){
                return true;

            }else{
                throw new Error(el.errorMessage);
            }

        }
        catch (ex) {
            console.error("Error : ", ex.message);
        }


    },

    validateSettingValues : function(setting, validate, values, error) {


        let validValue = false;

        Array.prototype.forEach.call(values, function(el, i){

            if(validate === "type"){
                
                if( typeof setting === el){
                    validValue = true;
                }

            }else if(validate === "value"){

                if(el === setting){
                    validValue = true;
                }

            }

        });

        try {

            if( validValue  ){
                return true;
            }else{
                throw new Error(error);
            }

        }
        catch (ex) {
            console.error("Error : ", ex.message);
        }

    },

    getContentType : function(o: any) {
        try {
            if( typeof o._options.content === "string" || typeof o._options.content === "object"){

                let contentType = typeof o._options.content;

                if(this.isDOM(o._options.content)){
                    // @ts-ignore
                    contentType = "domNode";
                }

                return contentType;

            }else{
                throw new Error("The content option must be a DOM selector or Node.");
            }
        }
        catch (ex) {
            console.error("Error : ", ex.message);
        }
    },

    extendDefaults: function (source: any, properties: any) {
        let property;
        for (property in properties) {
            if (properties.hasOwnProperty(property)) {
                source[property] = properties[property];
            }
        }
        return source;
    },

    isDOM  : function(obj) {
        // DOM, Level2
        if ("HTMLElement" in window) {
            return (!!obj && obj instanceof HTMLElement);
        }
        // Older browsers
        return (!!obj && typeof obj === "object" && obj.nodeType === 1 && !!obj.nodeName);
    }
};

export default gdkBaseComponent;
