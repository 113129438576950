import React, { ChangeEvent } from "react";
import { ReactElement } from "react";
import * as DashboardStore from "../../store/DashboardStore";
import { connect } from "react-redux";
import DanlawVehicle from "../../models/DanlawVehicle";
import "@gdk/base/dist/styles/gdk-base.css";
import DeviceShipment from "../../models/DeviceShipment";
import DeviceDetailsFlyout from "./DeviceDetailsFlyout";
import DeviceNotification from "../../models/DeviceNotification";
import DeviceNotificationsDisplay from "./DeviceNotificationsDisplay";
import DeviceReplacementModal from "./DeviceReplacementModal";
import { PolicyInfo } from "../../models/PolicyInfo";

interface DevicesTabProps {
    vehicles: DanlawVehicle[];
    selectedVehicle: DanlawVehicle;
    selectedVehicleId: string;
    shipments: DeviceShipment[];
    redAlertDeviceNotifications: DeviceNotification[];
    grayAlertDeviceNotifications: DeviceNotification[];
    greenAlertDeviceNotifications: DeviceNotification[];
    installNoticeDeviceNotifications: DeviceNotification[];
    policy: PolicyInfo;
    error: string;
}

interface DevicesTabState {
    vehicles: DanlawVehicle[];
    selectedVehicle: DanlawVehicle;
    selectedVehicleId: string;
    shipments: DeviceShipment[];
    isTooltipVisible: boolean;
    redAlertDeviceNotifications: DeviceNotification[];
    grayAlertDeviceNotifications: DeviceNotification[];
    greenAlertDeviceNotifications: DeviceNotification[];
    installNoticeDeviceNotifications: DeviceNotification[];
}

export class DevicesTab extends React.Component<any, any> {
    interval!: NodeJS.Timeout;
    constructor(props: any) {
        super(props);
        this.state = {
            vehicles: this.props.vehicles,
            selectedVehicle: this.props.selectedVehicle,
            shipments: this.props.shipments,
            isTooltipVisible: false,
            redAlertDeviceNotifications: this.props.redAlertDeviceNotifications,
            grayAlertDeviceNotifications: this.props.grayAlertDeviceNotifications,
            greenAlertDeviceNotifications: this.props.greenAlertDeviceNotifications,
            installNoticeDeviceNotifications: this.props.installNoticeDeviceNotifications,
        };
    }

    componentDidMount() {
        this.interval = setInterval(() => this.setVehiclesAndShipmentsStateForCustomerView(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    setVehiclesAndShipmentsStateForCustomerView() {
        if (!this.state.vehicles) {
            this.setState({vehicles: this.props.vehicles});
        }
        if (!this.state.shipments) {
            this.setState({shipments: this.props.shipments});
        }
        if (this.state.shipments && this.state.vehicles) {
            clearInterval(this.interval);
        }
    }

    getDeviceStatusStyle = (deviceStatus: string | undefined): string => {
        switch (deviceStatus) {
            case "Connected":
                return "success";
            case "DisConnected":
                return "alert";
            case "Ready For Install":
                return "warning";
            default:
                return "neutral";
        }
    };

    getShipmentStatusStyle = (shipmentStatus: string | undefined): string => {
        switch (shipmentStatus) {
            case "Delivered":
                return "success";
            case "On the Way":
                return "generic";
            default:
                return "neutral";
        }
    };

    showDeviceDetails(vehicle: DanlawVehicle): void {
        this.setState({
            selectedVehicle: vehicle,
            selectedVehicleId: vehicle.id,
        });
    }

    closeDeviceDetails(): void {
        this.setState({
            selectedVehicle: null,
            selectedVehicleId: null,
        });
    }

    render(): ReactElement {
        const { error } = this.props;
        const { vehicles } = this.state;
        return (
            <>
                <div className="container devices-container" id="devices-container" style={{display:"none"}}>
                    <>
                    {this.state.redAlertDeviceNotifications && this.state.redAlertDeviceNotifications.length > 0 && (
                        <DeviceNotificationsDisplay 
                            notificationsList={this.state.redAlertDeviceNotifications} 
                            divClassName="alert--high-importance" 
                            spanClassName="icon-alert" 
                            id="asdf1"
                        />
                    )}
                    {this.state.grayAlertDeviceNotifications && this.state.grayAlertDeviceNotifications.length > 0 && (
                        <DeviceNotificationsDisplay 
                            notificationsList={this.state.grayAlertDeviceNotifications} 
                            divClassName="alert--low-importance" 
                            spanClassName="icon-info" 
                            id="asdf2"
                        />
                    )}
                    {this.state.installNoticeDeviceNotifications && this.state.installNoticeDeviceNotifications.length > 0 && (
                        <DeviceNotificationsDisplay 
                            notificationsList={this.state.installNoticeDeviceNotifications} 
                            divClassName="alert--install-notice" 
                            spanClassName="icon-geolocation" 
                            id="asdf3"
                        />
                    )}
                    {this.state.greenAlertDeviceNotifications && this.state.greenAlertDeviceNotifications.length > 0 && (
                        <DeviceNotificationsDisplay 
                            notificationsList={this.state.greenAlertDeviceNotifications} 
                            divClassName="alert--green-affirmations" 
                            spanClassName="icon-confirmation" 
                            id="asdf4"
                        />
                    )}                        
                    </>
                    <div className="row">
                        <h3 className="devices-list-title">Devices</h3>
                    </div>
                    {this.state.shipments && this.state.shipments.data?.length > 0 && (
                        <>
                        <div className="data-table devices-shipment-table">
                            <table id="device-shipments-table" className="table devices-table">
                                <thead>
                                    <tr>
                                        <th>Shipment</th>
                                        <th>Status</th>
                                        <th>Address</th>
                                        <th>Devices</th>
                                        <th>Est. Arrival</th>
                                        <th>Tracking</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.shipments.data?.map(
                                    (deviceShipment: DeviceShipment) => {
                                        let shipmentStatus = deviceShipment.shipmentStatus || "--";
                                        let vehicleChipClass = shipmentStatus === "--" ? "": "text-left chips chips-" + this.getShipmentStatusStyle(shipmentStatus);
                                        return (                                
                                    <tr>
                                        <td data-title="Shipment">{deviceShipment.orderId}</td>
                                        <td data-title="Status"><p className={vehicleChipClass}>{shipmentStatus}</p></td>
                                        <td data-title="Address">
                                        
                                            {deviceShipment.address?.firstName}{" "}
                                            {deviceShipment.address?.lastName}
                                            {(deviceShipment.address?.firstName || deviceShipment.address?.lastName) && <br />}
                                            {deviceShipment.address?.businessName && (
                                                <>
                                                    {deviceShipment.address.businessName}
                                                    <br />
                                                </>
                                            )}
                                            {deviceShipment.address?.addressLine1}{', '}
                                            {deviceShipment.address?.addressLine2 && `${deviceShipment.address.addressLine2}, `}
                                            {deviceShipment.address?.city}{', '}
                                            {deviceShipment.address?.state}{', '}
                                            {deviceShipment.address?.zipCode}
                                                                            
                                        </td>
                                        <td data-title="Devices">
                                                {deviceShipment.deviceIds.map((f) => (
                                                    <>
                                                        {f} <br />
                                                    </>
                                                ))}                                            
                                        </td>
                                        <td data-title="Est. Arrival">
                                            {deviceShipment?.estimatedDeliveryDate ? new Date(Date.parse(deviceShipment?.estimatedDeliveryDate)).toLocaleString() : "--"}
                                        </td>
                                        <td data-title="Tracking">
                                            <a href={`https://www.ups.com/track?track=yes&trackNums=${deviceShipment?.trackingNumber}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span className="icon-open-new-tab" style={{ fontSize: '1.5em'}} />
                                                    {deviceShipment?.trackingNumber}
                                                </span>
                                            </a>
                                            
                                        </td>
                                    </tr>
                                    )}
                                )}
                                </tbody>
                            </table>
                        </div>
                   </>
                    )}
                    <p>Your device(s) will be listed here once they have been installed.</p>

                    {this.state.vehicles && this.state.vehicles.length > 0 && (
                        <>
                        <div className="data-table devices-shipment-table">
                        <table id="devices-list" className="table devices-table ">
                            <thead>
                                <tr>
                                    <th>Device</th>
                                    <th>Status</th>
                                    <th>Vehicle</th>
                                    <th>Location</th>
                                    <th>Last Connection</th>
                                    <th className="replacement-column"> </th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.vehicles?.map((vehicle: DanlawVehicle) => { 
                                let deviceStatus = vehicle?.deviceInfo?.deviceStatus || "--";
                                let vehicleChipClass = deviceStatus === "--"? "": "text-left chips chips-" + vehicle?.deviceInfo?.style;
                                let dateString = "";
                                if (vehicle?.deviceInfo?.lastConnectionTime){
                                    let dateAsDate = Date.parse(vehicle.deviceInfo.lastConnectionTime);
                                    dateString = new Date(dateAsDate).toLocaleString();
                                }
                                return (
                             
                                <tr>
                                    <td data-title="Device">
                                        {vehicle?.deviceInfo && vehicle.deviceInfo.deviceId ? (
                                            <a href="#" onClick={(event) => {event.preventDefault();this.showDeviceDetails(vehicle);}}>
                                                {`OBD ...${vehicle.deviceInfo.deviceId.slice(-4)}`}
                                            </a>
                                        ) : (
                                            "--"
                                        )}
                                    </td>
                                    <td data-title="Status"><p className={vehicleChipClass}>{deviceStatus}</p></td>
                                    <td data-title="Vehicle">
                                        {vehicle?.vehicleType}
                                        <br></br>
                                        <span data-qm-encrypt="true">VIN {"****" + vehicle?.vin?.slice(-6)}</span>
                                        <br></br>
                                    </td>
                                    <td data-title="Location">
                                        {vehicle?.nearestAddress ? (
                                            <>
                                                <span data-qm-encrypt="true">{vehicle.nearestAddress.addressLine1},{" "}</span>
                                                {vehicle.nearestAddress.city},{" "}
                                                {vehicle.nearestAddress.state}{" "}
                                                {vehicle.nearestAddress.zipCode}
                                            </>
                                        ) : (
                                            "--"
                                        )}
                                    </td>
                                    <td data-title="Last Connection">
                                        {vehicle?.deviceInfo?.lastConnectionTime ? dateString : "--"}
                                    </td>
                                    <td className="replacement-column">
                                        <div onClick={() => this.setState({selectedVehicle: vehicle})} ><span className="geico-icon geico-icon--small geico-icon--actionable icon-more-dots open-replacement-modal"></span></div>
                                    </td>
                                </tr>
                                )}
                            )}
                            </tbody>
                        </table>
                    </div>
                        </>
                    )}
                    {this.state.selectedVehicle != null &&
                        this.state.selectedVehicleId != null && (
                            <div style={{display: this.state.selectedVehicle ? "block" : "none"}} className="edit-drawer-devices-tab">
                                <div className="edit-drawer-wrapper">
                                    <div className="edit-drawer-bg"></div>
                                    <DeviceDetailsFlyout
                                        selectedVehicle={this.state.selectedVehicle}
                                        onClose={() => this.closeDeviceDetails()}
                                    />
                                </div>
                            </div>
                        )}
                    <DeviceReplacementModal selectedVehicle={this.state.selectedVehicle} onClose={() => console.dir()} error={error} />
                </div>
            </>
        );
    }
}
const mapStateToProps = (state: any) => ({
    selectedVehicle: state.dshs.selectedVehicle,
    selectedVehicleId: state.dshs.selectedVehicleId,
    policy: state.dshs.policy,
    shipments: state.dshs.shipments,
});

export default connect(
    mapStateToProps,
    DashboardStore.actionCreators
)(DevicesTab as any);